import CASL from '@/utils/constants/casl'

export default {
  path: 'role',
  children: [
    {
      path: '',
      name: 'role.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.TEAM
      },
      component: () => import('./list/RoleListPage.vue')
    }
  ]
}
