import CASL from '@/utils/constants/casl'

export default {
  path: 'troubleshooting',
  children: [
    {
      path: '',
      name: 'troubleshooting.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.TROUBLESHOOTING
      },
      component: () => import('./list/TroubleshootingListPage.vue')
    }
  ]
}
