import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/pages/dashboard/routes'
import Atpm from '@/pages/atpm/routes'
import User from '@/pages/user/routes'
import Team from '@/pages/team/routes'
import Role from '@/pages/role/routes'
import DataInput from '@/pages/data-input/routes'
import SerialNumber from '@/pages/serial-number/routes'
import Confirmation from '@/pages/confirmation/routes'
import Survey from '@/pages/survey/routes'
import ProcessPln from '@/pages/process-pln/routes'
import PLNMobile from '@/pages/pln-mobile/routes'
import OptionLibrary from '@/pages/option-library/routes'
import Delivery from '@/pages/delivery/routes'
import Installation from '@/pages/installation/routes'
import Troubleshooting from '@/pages/troubleshooting/routes'
import DataProblem from '@/pages/data-problem/routes'
import TypeCharger from '@/pages/type-charger/routes'
import AppUser from '@/pages/app-user/routes'
import AppContent from '@/pages/app-content/routes'
import AppTermsAndCondition from '@/pages/app-t&c/routes'
import AppPrivacyPolicy from '@/pages/app-privacy-policy/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        Dashboard,
        Atpm,
        User,
        Team,
        DataInput,
        SerialNumber,
        Role,
        Confirmation,
        Survey,
        ProcessPln,
        PLNMobile,
        OptionLibrary,
        Delivery,
        Installation,
        Troubleshooting,
        DataProblem,
        // TypeCharger,
        // AppUser,
        // AppContent,
        // AppTermsAndCondition,
        // AppPrivacyPolicy
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/login/LoginPage.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: '/login' }
  ]
})

export default router
