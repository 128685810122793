const ACCESS = {
  DASHBOARD: 'DASHBOARD',
  ATPM: 'ATPM',
  USER: 'USER',
  TEAM: 'USER',
  ROLE: 'USER',
  DATA_INPUT: 'DATA_INPUT',
  SERIAL_NUMBER: 'SERIAL_NUMBER',
  CONFIRMATION: 'KONFIRMASI',
  SURVEY: 'SURVEY',
  PROCESS: 'PROSES_PLN',
  DELIVERY: 'DELIVERY',
  PLN_MOBILE: 'PLN_MOBILE',
  INSTALLATION: 'INSTALASI',
  DATA_PROBLEM: 'DATA_PROBLEM',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  OPTION_LIBRARY: 'OPSI_LIBRARY',
  MOBILE_APP_SETUP: 'MOBILE_APP_SETUP'
}

export default { ACCESS }
