import CASL from '@/utils/constants/casl'

export default {
  path: 'installation',
  children: [
    {
      path: '',
      name: 'installation.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.INSTALLATION
      },
      component: () => import('./list/InstallationListPage.vue')
    }
  ]
}
