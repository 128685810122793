import CASL from '@/utils/constants/casl'

export default {
  path: 'team',
  children: [
    {
      path: '',
      name: 'team.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.TEAM
      },
      component: () => import('./list/TeamListPage.vue')
    }
  ]
}
