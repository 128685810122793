import CASL from '@/utils/constants/casl'

export default {
  path: 'user',
  children: [
    {
      path: '',
      name: 'user.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.USER
      },
      component: () => import('./list/UserListPage.vue')
    }
  ]
}
