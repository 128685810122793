import CASL from '@/utils/constants/casl'

export default {
  path: 'confirmation',
  children: [
    {
      path: '',
      name: 'confirmation.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.CONFIRMATION
      },
      component: () => import('./list/ConfirmationListPage.vue')
    }
  ]
}
