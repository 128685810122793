import CASL from '@/utils/constants/casl'

export default {
  path: 'data-input',
  children: [
    {
      path: '',
      name: 'data-input.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.DATA_INPUT
      },
      component: () => import('./list/DataInputListPage.vue')
    }
  ]
}
